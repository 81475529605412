import React, { useContext, useState, useEffect } from "react";
import { Rating } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { AuthContext } from "../../contexts/AccountContext";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { ProfileApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
const ReviewComponent = () => {
  const Star = (
    <path d="M62 25.154H39.082L32 3l-7.082 22.154H2l18.541 13.693L13.459 61L32 47.309L50.541 61l-7.082-22.152L62 25.154z" />
  ); // Source: https://www.svgrepo.com/svg/353297/star

  const customStyles = {
    itemShapes: Star,
    boxBorderWidth: 2,

    activeFillColor: ["#FEE2E2", "#FFEDD5", "#FEF9C3", "#ECFCCB", "#D1FAE5"],
    activeBoxColor: ["#da1600", "#db711a", "#dcb000", "#61bb00", "#009664"],
    activeBoxBorderColor: [
      "#c41400",
      "#d05e00",
      "#cca300",
      "#498d00",
      "#00724c",
    ],

    inactiveFillColor: "white",
    inactiveBoxColor: "#dddddd",
    inactiveBoxBorderColor: "#a8a8a8",
  };
  const authContext = useContext(AuthContext);
  const [rating, setRating] = useState(0);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const { showNewMessages } = useIntercom();
  let accountApi = new ProfileApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);

  useEffect(() => {}, []);

  // Catch Rating value
  const handleRating = (rate: number) => {
    console.log("handleRating", rate);
    setRating(rate);
    if (rate < 5) {
      showNewMessages(
        "Thanks for your feedback. Please let us know how we can improve."
      );
    } else {
      //show modal
      setShowReviewModal(true);
    }

    submitRatingEvent(rate);
  };

  const submitRatingEvent = (rating: number) => {
    accountApi
        .profileSubmitFeedbackRatingPost(rating)
        .then((response) => {
          
        })
        .catch((error: any) => {});
    };

    const submitAppstoreReviewEvent = () => {
      accountApi
          .profileSubmitAppStoreReviewPost(authContext.account?.integration?.toString())
          .then((response) => {
            authContext.refresh();
          })
          .catch((error: any) => {});
      };

  return (
    <React.Fragment>
      <div
        className="bg-light position-relative d-flex p-2 rounded shadow-sm m-5 mr-0"
        role="alert"
      >
        <div className="w-1 bg-primary rounded-pill"></div>
        <div className="col">
          <div className="float-end p-2">
            <Rating
              style={{ maxWidth: 250 }}
              value={rating}
              onChange={handleRating}
              itemStyles={customStyles}
              radius="large"
              spaceBetween="small"
              spaceInside="large"
            />
          </div>
          <div className="ms-4 py-2 d-flex">
            {/* <div className="pe-3 text-lg text-primary">
      <i className="bi bi-alarm"></i>
    </div> */}
            <div className="">
              <span className="d-block font-semibold text-heading">
                How's your experience with LoudHippo?
              </span>
              <p className="text-sm">Rate us by clicking on the stars</p>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showReviewModal} onHide={() => setShowReviewModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Please leave a review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center py-5 px-5">
            <div className="icon icon-shape rounded-circle bg-opacity-30 bg-danger text-danger text-lg">
              <i className="bi bi-heart-fill"></i>
            </div>
            <h3 className="mt-7 mb-4">Thanks for the love!</h3>
            <p className="text-sm text-muted">
              Your valuable feedback encourages us to keep improving our app.
              Please take a moment to share your experience with other merchants
              on Shopify. Thanks a million!
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-primary"
            href="https://apps.shopify.com/loudhippo-post-purchase-survey?#modal-show=WriteReviewModal"
            target="_blank"
            onClick={()=>submitAppstoreReviewEvent()}
          >
            Review us on Shopify
          </a>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ReviewComponent;
