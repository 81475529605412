import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "../components/NavMenu";
import { Outlet } from 'react-router-dom';
import TopNavBar from "../components/TopNavBar";
import CrispChat from "../components/CrispChat";
import { AuthProvider } from "../contexts/AccountContext";
import { Toaster } from "react-hot-toast";
export class LoginLayout extends Component {
  static displayName = LoginLayout.name;

  render() {
    return (
      <div>
        <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">

          {/* <!-- Content --> */}
          <div className="flex-lg-1 h-screen overflow-y-lg-auto">
            {/* <!-- Navbar --> */}
            <Toaster position={"top-right"}/>
            {/* <NavMenu /> */}
            {/* <Container>{this.props.children}</Container> */}
            <Outlet /> 
          </div>
        </div>
      {/* <CrispChat /> */}
      </div>
    );
  }
}
