import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "../components/NavMenu";
import SideBar from "../components/SideBar";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "../contexts/AccountContext";
import TopNavBar from "../components/TopNavBar";
import CrispChat from "../components/CrispChat";
import { Toaster } from "react-hot-toast";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import IntercomChat from "../components/IntercomChat";
export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <AuthProvider>
        <div>
          <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
            {/* <!-- Sidebar --> */}
            {/* <SideBar /> */}

            {/* <!-- Content --> */}
            <div className="flex-lg-1 h-screen overflow-y-lg-auto">
              {/* <!-- Navbar --> */}
              <TopNavBar showMenu={true} />
              {/* <NavMenu /> */}
              {/* <Container>{this.props.children}</Container> */}
              <Toaster position={"top-right"} />
              <IntercomProvider appId={"l1nsvmiv"} initializeDelay={5000}>
                <Outlet />
                <IntercomChat />
              </IntercomProvider>
              {/* <Outlet /> */}
            </div>
          </div>
        </div>
        {/* <CrispChat /> */}
      </AuthProvider>
    );
  }
}
