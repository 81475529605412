/* tslint:disable */
/* eslint-disable */
/**
 * LoudHippo API
 * An API for LoudHippo Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@loudhippo.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const BillingPlan = {
    Free: 'Free',
    Orders1K: 'Orders_1K',
    Orders5K: 'Orders_5K',
    Orders10K: 'Orders_10K',
    Orders30K: 'Orders_30K',
    Orders100K: 'Orders_100K',
    Enterprise: 'Enterprise',
    Orders1KAnnual: 'Orders_1K_Annual',
    Orders5KAnnual: 'Orders_5K_Annual',
    Orders10KAnnual: 'Orders_10K_Annual',
    Orders30KAnnual: 'Orders_30K_Annual',
    Orders100KAnnual: 'Orders_100K_Annual',
    Staff: 'Staff',
    Foundation: 'Foundation'
} as const;

export type BillingPlan = typeof BillingPlan[keyof typeof BillingPlan];



