import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import {
  ProfileApi,
  SetupChecklistViewModel,
  SurveyApi,
  SurveyTopLevelStatsModel,
} from "../../apiclient";
import { apiConfig } from "../../config";
import { FormCheck, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import ToggleSurveyBanner from "../../components/ToggleSurveyBanner";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import ReviewComponent from "./ReviewComponent";
import { darkToast } from "../../js/darktoast";

const HomeOnboardingPage = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [statsPanelModel, setStatsPanelModel] = useState(
    {} as SurveyTopLevelStatsModel
  );
  const hasUpgraded = searchParams.get("upgraded") ?? "";
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  let surveyApi = new SurveyApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  let profileApi = new ProfileApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as SetupChecklistViewModel);
  const [isLoading, setLoading] = useState(false as boolean);
  const [checklistItemsCompleted, setChecklistItemsCompleted] = useState(
    0 as number
  );
  const [checklistItemsCompletedPercent, setChecklistItemsCompletedPercent] =
    useState(0 as number);
  const [activeChecklist, setActiveChecklist] = useState(1 as number);
  const [isSurveyEnabled, setIsSurveyEnabled] = useState(false as boolean);

  useEffect(() => {
    if (hasUpgraded) {
      toast.success(
        "Your 14-day free trial has started. All features have been unlocked.",
        { duration: 5000 }
      );
    }
    setIsSurveyEnabled(authContext.account?.surveyEnabled!);
     getPreviewUrl();
    fetchTopPanelData();
    fetchChecklistData();
  }, []);

  const fetchTopPanelData = () => {
    surveyApi
      .surveyGetTopLevelStatsForAccountGet()
      .then((response) => {
        var data = response.data;
        setStatsPanelModel(data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log("get survey error", error);
        setLoading(false);
      });
  };

  const toggleSurveyEnabled = (val: any) => {
    console.log("toggleSurveyEnabled", val);
    var toggleState = !isSurveyEnabled;
    setIsSurveyEnabled(toggleState);

    surveyApi
      .surveyToggleStatusByIdIdGet(authContext.account?.publishedSurveyId ?? "")
      .then((response) => {
        if (toggleState) {
          darkToast.success("Survey enabled.");
          setShowConfetti(true);
        } else {
          darkToast.success("Survey disabled.");
        }
        authContext.refresh(); //refresh context;
        onSurveyBannerToggled();
      })
      .catch((error: any) => {
        console.log("toggle survey error", error);

        setLoading(false);
      });
  };

  const getPreviewUrl = () => {
    setIframeUrl(
      `/Preview/Survey?accountId=${
        authContext.account?.accountId
      }&ts=${Date.now()}`
    );
  };

  const onSurveyBannerToggled = () => {
    console.log("onSurveyBannerToggled");
    fetchChecklistData();
  };

  const fetchChecklistData = () => {
    profileApi
      .profileSetupChecklistGet()
      .then((response) => {
        var data = response.data;
        console.log("fetchChecklistData", data);
        setModel(data);
        var numComplete = 0;
        if (data.customizedSurveyCompleted) numComplete++;
        if (data.enabledSurveyCompleted) numComplete++;
        if (data.integrationConnectedCompleted) numComplete++;
        setChecklistItemsCompleted(numComplete);

        var percentComplete = Math.round((numComplete / 3) * 100);
        setChecklistItemsCompletedPercent(percentComplete);

        if (!data.customizedSurveyCompleted) {
          setActiveChecklist(1);
        } else if (!data.enabledSurveyCompleted) {
          setActiveChecklist(2);
        } else if (!data.integrationConnectedCompleted) {
          setActiveChecklist(3);
        } else {
          setActiveChecklist(4);
        }
        setLoading(false);
      })
      .catch((error: any) => {
        console.log("fetchChecklistData error", error);

        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ToggleSurveyBanner onToggled={onSurveyBannerToggled} />
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={500}
        />
      )}
      <div className="d-xl-flex row">
        <div className="col-md-7">
          <header>
            {authContext.account?.integration === "Shopify" && !authContext.account?.appStoreReviewSubmittedAt &&
            <ReviewComponent />}
            <div className="container-fluid">
              <div className="border-bottom pt-6 pb-4">
                <div className="row align-items-center">
                  <div className="col-sm col-12">
                    <h1 className="h2 ls-tight">Welcome to LoudHippo</h1>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <main className="py-6">
          {isLoading ? <Spinner className="ms-auto" animation="border" /> :(
            <div className="container-fluid px-xl-8 vstack gap-3">
              <div className="card">
                <div className="px-4 px-md-8 pb-4">
                  <div className="d-flex align-items-center pt-5">
                    <div className="me-auto ">
                      <h5 className="font-semibold">
                        <span>Setup guide</span>
                      </h5>
                      <p className="text-sm text-muted mb-3">
                        Use this guide to get your post-purchase survey up and
                        running.
                      </p>
                    </div>
                  </div>
                  <div className="row mb-4 pb-5 border-bottom">
                    <div className="d-flex align-items-center">
                      <div className="text-sm pr-25">
                        {checklistItemsCompleted} of 3 tasks complete
                      </div>

                      <div className="col px-0 text-end">
                        <div style={{ width: "100%" }}>
                          <div
                            className="progress progress-sm shadow-none"
                            style={{ height: "8px" }}
                          >
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              style={{
                                width: checklistItemsCompletedPercent + "%",
                              }}
                              aria-valuenow={checklistItemsCompletedPercent}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list-group list-group-flush">
                    <div className="list-group-item py-1 border-0">
                      <div className="row g-3 align-items-center">
                        <div className="col-sm-11 col-12">
                          <div className="form-check ">
                            {model.customizedSurveyCompleted ? (
                              <input
                                className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                type="checkbox"
                                checked
                              />
                            ) : (
                              <input
                                className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                type="checkbox"
                              />
                            )}
                            <a
                              onClick={() => setActiveChecklist(1)}
                              className={
                                "form-check-label ms-2 cursor-pointer " +
                                (activeChecklist === 1
                                  ? "font-bold"
                                  : "font-regular")
                              }
                            >
                              Customize your survey
                            </a>
                          </div>
                          {activeChecklist == 1 && (
                            <div className="row">
                              <div className="col-md-8 py-5">
                                <p className="text-sm text-muted mb-3">
                                  Create an on-brand survey that fits seamlessly
                                  into your order status page. Change everything
                                  from your questions to your design.
                                </p>
                                <Link
                                  className="btn btn-sm btn-primary"
                                  to={`/Surveys/Edit`}
                                >
                                  Customize survey
                                </Link>
                              </div>
                              <div className="col-md-4">
                                <picture>
                                  <source
                                    type="image/avif"
                                    srcSet="/img/checklist/loud-hippo_customize.webp"
                                  />
                                  <img src="/img/checklist/loud-hippo_customize.png" />
                                </picture>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item py-1 border-0">
                      <div className="row g-3 align-items-center">
                        <div className="col-sm-11 col-12">
                          <div className="form-check ">
                            {model.enabledSurveyCompleted ? (
                              <input
                                className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                type="checkbox"
                                checked
                              />
                            ) : (
                              <input
                                className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                type="checkbox"
                              />
                            )}
                            <a
                              onClick={() => setActiveChecklist(2)}
                              className={
                                "form-check-label ms-2 cursor-pointer " +
                                (activeChecklist === 2
                                  ? "font-bold"
                                  : "font-regular")
                              }
                            >
                              Enable your survey
                            </a>
                          </div>
                          {activeChecklist === 2 && (
                            <div className="row">
                              <div className="col-md-8 py-5">
                                <p className="text-sm text-muted mb-3">
                                  Start collecting valuable post-purchase survey
                                  insights by enabling your survey to add it to
                                  your order status page.
                                </p>
                                {model.enabledSurveyCompleted ? (
                                  <div
                                    onClick={toggleSurveyEnabled}
                                    className="btn btn-sm btn-neutral"
                                  >
                                    Disable survey
                                  </div>
                                ) : (
                                  <div
                                    onClick={toggleSurveyEnabled}
                                    className="btn btn-sm btn-primary"
                                  >
                                    Enable survey
                                  </div>
                                )}
                              </div>
                              <div className="col-md-4">
                              <picture>
                                  <source
                                    type="image/webp"
                                    srcSet="/img/checklist/loud-hippo_enable-survey.webp"
                                  />
                                  <img src="/img/checklist/loud-hippo_enable-survey.png" />
                                </picture>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item py-1 border-0">
                      <div className="row g-3 align-items-center">
                        <div className="col-sm-11 col-12">
                          <div className="form-check ">
                            {model.integrationConnectedCompleted ? (
                              <input
                                className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                type="checkbox"
                                checked
                              />
                            ) : (
                              <input
                                className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                type="checkbox"
                              />
                            )}
                            <a
                              onClick={() => setActiveChecklist(3)}
                              className={
                                "form-check-label ms-2 cursor-pointer " +
                                (activeChecklist === 3
                                  ? "font-bold"
                                  : "font-regular")
                              }
                            >
                              Sync data to your marketing stack
                            </a>
                          </div>
                          {activeChecklist === 3 && (
                            <div className="row">
                              <div className="col-md-8 py-5">
                                <p className="text-sm text-muted mb-3">
                                  Push data anywhere to action your insights and
                                  grow your ROI with better analytics, targeting
                                  and marketing personalization.
                                </p>
                                <Link
                                  className="btn btn-sm btn-primary"
                                  to={`/manage-integrations`}
                                >
                                  Add integration
                                </Link>
                              </div>
                              <div className="col-md-4">
                              <picture>
                                  <source
                                    type="image/webp"
                                    srcSet="/img/checklist/loud-hippo_integrations.webp"
                                  />
                                  <img src="/img/checklist/loud-hippo_integrations.png" />
                                </picture>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-center border-bottom pb-5">
                    <h5 className="mb-0">Post-purchase survey analytics</h5>
                    <div className="ms-auto text-end">
                      <Link
                        className="link-primary font-bold text-sm"
                        to={`/Surveys/Analytics`}
                      >
                        View analytics
                      </Link>
                    </div>
                  </div>
                  <div className="row g-6 mb-6">
                    <div className="col-xl-3 col-sm-6 col-12">
                      <div className="card bg-surface-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                Views
                              </span>
                              <span className="h3 font-bold mb-0">
                                {statsPanelModel.views ?? 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                      <div className="card bg-surface-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                Responses
                              </span>
                              <span className="h3 font-bold mb-0">
                                {statsPanelModel.responses ?? 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                      <div className="card bg-surface-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                Response Rate
                              </span>
                              <span className="h3 font-bold mb-0">
                                {(statsPanelModel.responseRate ?? 0).toFixed(1)}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                      <div className="card bg-surface-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                Completion Rate
                              </span>
                              <span className="h3 font-bold mb-0">
                                {(statsPanelModel.completionRate ?? 0).toFixed(
                                  1
                                )}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
          </main>
        </div>
        <div className="col-md-5 flex-xl-none border-start-xl bg-surface-primary h-xl-calc position-sticky top-xl-18">
          <aside className="p-8 h-full overflow-y-xl-auto">
            <div className="vstack gap-6">
              <div>
                <div className="d-flex align-items-center mb-4 pb-5 border-bottom">
                  <h5 className="me-auto">Survey preview</h5>
                  <Link
                    className="link-primary font-bold text-sm"
                    to={`/Surveys/Edit`}
                  >
                    <i className="bi bi-pencil me-2"></i>Edit survey
                  </Link>
                </div>

                <div className="border previewSurveyIframe">
                  <iframe
                  loading="lazy"
                    title="order-preview"
                    src={iframeUrl}
                    style={{ width: "100%", height: "800px" }}
                  />
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeOnboardingPage;
