import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { ProfileViewModel } from "../apiclient";

const initializeTracking = (model: ProfileViewModel) => {
    gaAnalytics(model);
}

const gaAnalytics = (model: ProfileViewModel) => {
    // ================ GA EVENT TRACKING =======================

    (window as any).dataLayer = (window as any).dataLayer || [];

    if ((window as any).dataLayer) {
        (window as any).dataLayer.push({
            event: "identifyUser",
            userId: model?.accountId,
        });

        var gAIntegrationTracking = Cookies.get("GAIntegrationTracking");
        if (gAIntegrationTracking) {
            (window as any).dataLayer.push({
                event: "identifyIntegration",
                integrationType: gAIntegrationTracking,
            });
        }
        var gAEventTracking = Cookies.get("GAEventTracking");
        if (gAEventTracking) {
            (window as any).dataLayer.push({
                event: gAEventTracking,
            });
        }
    }
};


export { initializeTracking };

