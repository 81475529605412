import React, { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "../contexts/AccountContext";

export interface CrispChatProps {
  openChat: () => void,
  pushMessageText: (textMessage: string) => void
}
const initialState = {
  openChat: () => {
    (window as any).$crisp.push(["do", "chat:open"]);
  },
  pushMessageText: (textMessage: string) => {
    console.log("pushMessageText", textMessage);
    (window as any).$crisp.push(["set", "message:text", [textMessage]]);
    //console.log((window as any).$crisp);

  }
}

const CrispChatContext = createContext(initialState);

const CrispChat = () => {
  const authContext = useContext(AuthContext);
  useEffect(() => {
    
    (window as any).$crisp = [];
    (window as any).CRISP_WEBSITE_ID = "292c66a8-82ce-4840-ad53-fc6df0f9d6e9";
    (() => {
      const d = document;
      const s = d.createElement("script") as any;
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("body")[0].appendChild(s);
    })();

    var email = authContext.account?.emailAddress;
    var name = authContext.account?.name;
    var accountId = authContext.account?.accountId;
    console.log("email", email);
    console.log("name", name);
    (window as any).$crisp.push(["set", "user:company", [authContext.account?.companyName, { url: authContext.account?.website }]]);
    (window as any).$crisp.push(["set", "user:email", [authContext.account?.emailAddress]]);
    (window as any).$crisp.push(["set", "user:phone", [authContext.account?.phone]]);
    (window as any).$crisp.push(["set", "user:nickname", [authContext.account?.name]]);

    (window as any).$crisp.push(["set", "session:data", [
      [
        ["integration_domain", authContext.account?.integrationDomain],
        ["integration_plan_name", authContext.account?.integraitonPlanName],
        ["billing_plan", authContext.account?.billingPlan],
        ["platform", authContext.account?.integration],
        ["account_id", accountId],
        ["monthly_orders", authContext.account?.monthlyOrderCount],
        ["account_status", authContext.account?.accountStatus],
        ["survey_enabled", authContext.account?.surveyEnabled],
        ["billing_accepted_at", authContext.account?.billingAcceptedAt],
        ["business_category", authContext.account?.businessCategory],
        ["business_type", authContext.account?.businessType],
        ["billing_subscription_price", authContext.account?.billingSubscriptionPrice],
        ["onboarding_completed_at", authContext.account?.onboardingCompletedProfileAt],
        ["notification_best_practice_enabled", authContext.account?.notificationBestPracticeEnabled],
        ["notification_marketing_enabled", authContext.account?.notificationMarketingEnabled],
        ["notification_daily_digest_enabled", authContext.account?.notificationDailyDigestEnabled],
        ["notification_weekly_digest_enabled", authContext.account?.notificationWeeklyDigestEnabled],
      ]
    ]]);

   // (window as any).$crisp.push(["do", "chat:open"]);
   // (window as any).$crisp.push(["set", "message:text", "Pricing please"]);
  });


    return (
        <React.Fragment>

        </React.Fragment>

    );
}

export default CrispChat;
export { CrispChatContext };
