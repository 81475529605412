import React, { Component } from "react";
import { Outlet } from 'react-router-dom';
import { Toaster } from "react-hot-toast";
export class BlankLayout extends Component {
  static displayName = BlankLayout.name;

  render() {
    return (
      <div>
        
            {/* <!-- Navbar --> */}
            <Toaster position={"top-right"}/>
            {/* <NavMenu /> */}
            {/* <Container>{this.props.children}</Container> */}
            <Outlet /> 
          </div>
    );
  }
}
