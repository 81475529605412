import axios from 'axios';
import { useEffect } from 'react'
import { Provider, useAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { AppBridgeState, ClientApplication } from "@shopify/app-bridge";
import {shopifyAppBridge} from "./config"

export const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use((config) => {

    if(shopifyAppBridge.app && Object.keys(shopifyAppBridge.app).length > 0){

        return getSessionToken(shopifyAppBridge.app) // requires an App Bridge instance
            .then((token) => {
                // Append your request headers with an authenticated token
                if(config.headers){
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
                return config;
            });
    } 
    else{
        return config;
    }
});

