import React, { lazy, Component, Suspense  } from 'react';
import { Route } from 'react-router-dom';
import './custom.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {Provider, Loading} from '@shopify/app-bridge-react';
import { Buffer } from 'buffer';
import { shopifyAppBridge } from './config';

import { LoginLayout } from './layouts/LoginLayout';
import { Layout } from './layouts/Layout';
import { BlankLayout } from './layouts/BlankLayout';
import { NoSidebarLayout } from './layouts/NoSidebarLayout';
import { Spinner } from "react-bootstrap";
import HomeOnboardingPage from './pages/home/HomeOnboardingPage';

const SurveyShopifyPosResponsePage = lazy(() => import('./pages/shopify/SurveyShopifyPosResponsePage'));
const SurveyShopifyMarketingPreviewPage = lazy(() => import('./pages/shopify/SurveyShopifyMarketingPreviewPage'));
const TemplateCreatePage = lazy(() => import('./pages/templates/TemplateCreatePage'));
const TemplateEditPage = lazy(() => import('./pages/templates/TemplateEditPage'));
const SurveyAnalyticsPage = lazy(() => import('./pages/surveys/analytics/SurveyAnalyticsPage'));
const SurveyQuestionAnalyticsPage = lazy(() => import('./pages/surveys/analytics/SurveyQuestionAnalyticsPage'));
const SurveyQuestionResponsesPage = lazy(() => import('./pages/surveys/analytics/SurveyQuestionResponsesPage'));
const UsersPage = lazy(() => import('./pages/account/UsersPage'));
const SignInPage = lazy(() => import('./pages/account/SignInPage'));
const TemplatePreviewPage = lazy(() => import('./pages/templates/TemplatePreviewPage'));
const SurveyPreviewOrderHtmlPage = lazy(() => import('./pages/surveys/SurveyPreviewOrderHtmlPage'));
const AdminLoginPage = lazy(() => import('./pages/admin/AdminLoginPage'));
const ChooseAccountPage = lazy(() => import('./pages/admin/ChooseAccountPage'));
const CompleteProfilePage = lazy(() => import('./pages/onboarding/CompleteProfilePage'));
const OnboardingChoosePlanPage = lazy(() => import('./pages/onboarding/OnboardingChoosePlanPage'));
const AccountNotificationsPage = lazy(() => import('./pages/account/AccountNotificationsPage'));
const CreateAccountPage = lazy(() => import('./pages/account/CreateAccountPage'));
const OnboardingConnectStore = lazy(() => import('./pages/onboarding/OnboardingConnectStore'));
const WooCommerceSetup = lazy(() => import('./pages/onboarding/WooCommerceSetup'));
const RedirectToShopifyAuthPage = lazy(() => import('./pages/shopify/RedirectToShopifyAuthPage'));
const RedirectToExternalPage = lazy(() => import('./pages/shopify/RedirectToExternalPage'));
const ApiKeysPage = lazy(() => import('./pages/integrations/ApiKeysPage'));
const FetchData = lazy(() => import('./components/FetchData'));
const Counter = lazy(() => import('./components/Counter'));
const TestPage = lazy(() => import('./components/TestPage'));
const AccountPage = lazy(() => import('./pages/account/AccountPage'));
const AccountPlanPage = lazy(() => import('./pages/account/AccountPlanPage'));
const IntegrationsPage = lazy(() => import('./pages/integrations/IntegrationsPage'));
const TemplatesPage = lazy(() => import('./pages/templates/TemplatesPage'));
const Surveys = lazy(() => import('./pages/surveys/SurveysPage'));
const SurveyEditPage = lazy(() => import('./pages/surveys/SurveyEditPage'));
const SurveyCreatePage = lazy(() => import('./pages/surveys/SurveyCreatePage'));
const SurveyPreview = lazy(() => import('./pages/surveys/SurveyPreviewPage'));
const NotFoundPage = lazy(() => import('./pages/error/NotFoundPage'));
const HomePage = lazy(() => import('./pages/home/HomePage'));
const EmailPage = lazy(() => import('./pages/email/EmailPage'));
const SurveyResponsePage = lazy(() => import('./pages/surveys/SurveyResponsePage'));

const routesConfig = [
    {
        element: <BlankLayout />,
        children: [
            {
                path: "/surveyorderhtmlpreview",
                element: <Suspense fallback={<Spinner animation="border" />}><SurveyPreviewOrderHtmlPage /></Suspense>,
            },
            {
                path: "/shopify/RedirectToShopifyAuthPage",
                element: <Suspense fallback={<Spinner animation="border" />}><RedirectToShopifyAuthPage /></Suspense>
            },
            {
                path: "/shopify/RedirectToExternalPage",
                element: <Suspense fallback={<Spinner animation="border" />}><RedirectToExternalPage /></Suspense>
            },
        ],
    },
    {
        element: <NoSidebarLayout />,
        children: [
            {
                path: "/surveypreview",
                element: <Suspense fallback={<Spinner animation="border" />}><SurveyPreview /></Suspense>,
            },
            {
                path: "/surveys/Create",
                element: <Suspense fallback={<Spinner animation="border" />}><SurveyCreatePage /></Suspense>,
            },
            {
                path: "/onboarding/complete-profile",
                element: <Suspense fallback={<Spinner animation="border" />}><CompleteProfilePage /></Suspense>,
            },
            {
                path: "/onboarding/woocommerce-setup",
                element: <Suspense fallback={<Spinner animation="border" />}><WooCommerceSetup /></Suspense>,
            },
            {
                path: "/onboarding/connect-store",
                element: <Suspense fallback={<Spinner animation="border" />}><OnboardingConnectStore /></Suspense>,
            },
            {
                path: "/onboarding/selectplan",
                element: <Suspense fallback={<Spinner animation="border" />}><OnboardingChoosePlanPage /></Suspense>,
            },
        ],
    },
    {
        element: <LoginLayout />,
        children: [
            {
                path: "/account/login",
                element: <Suspense fallback={<Spinner animation="border" />}><SignInPage /></Suspense>,
            },
            {
                path: "/account/createaccount",
                element: <Suspense fallback={<Spinner animation="border" />}><CreateAccountPage /></Suspense>,
            },
            {
                path: "/account/signup",
                element: <Suspense fallback={<Spinner animation="border" />}><CreateAccountPage /></Suspense>,
            },
            {
                path: "/admin/login/:accountId",
                element: <Suspense fallback={<Spinner animation="border" />}><AdminLoginPage /></Suspense>,
            },
            {
                path: "/account/chooseaccount",
                element: <Suspense fallback={<Spinner animation="border" />}><ChooseAccountPage /></Suspense>,
            }
        ],
    },
    {
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <Suspense fallback={<Spinner animation="border" />}><HomeOnboardingPage /></Suspense>,
            },
            {
                path: "/dashboard",
                element: <Suspense fallback={<Spinner animation="border" />}><HomeOnboardingPage /></Suspense>,
            },
            {
                path: "/surveys/analytics",
                element: <Suspense fallback={<Spinner animation="border" />}><SurveyAnalyticsPage /></Suspense>,
            },
            {
                path: "/surveys/question/:questionNameId/analytics",
                element: <Suspense fallback={<Spinner animation="border" />}><SurveyQuestionAnalyticsPage /></Suspense>,
            },
            {
                path: "/surveys/question/:questionNameId/responses",
                element: <Suspense fallback={<Spinner animation="border" />}><SurveyQuestionResponsesPage /></Suspense>,
            },
            {
                path: "/email",
                element: <Suspense fallback={<Spinner animation="border" />}><EmailPage /></Suspense>,
            },
            {
                path: "/surveys/edit",
                element: <Suspense fallback={<Spinner animation="border" />}><SurveyEditPage /></Suspense>,
            },
            {
                path: "/counter",
                element: <Suspense fallback={<Spinner animation="border" />}><Counter /></Suspense>,
            },
            {
                path: "/fetch-data",
                element: <Suspense fallback={<Spinner animation="border" />}><FetchData /></Suspense>,
            },
            {
                path: "/testpage",
                element: <Suspense fallback={<Spinner animation="border" />}><TestPage /></Suspense>,
            },
            {
                path: "/account/notifications",
                element: <Suspense fallback={<Spinner animation="border" />}><AccountNotificationsPage /></Suspense>,
            },
            {
                path: "/account/users",
                element: <Suspense fallback={<Spinner animation="border" />}><UsersPage /></Suspense>,
            },
            {
                path: "/billingplan",
                element: <Suspense fallback={<Spinner animation="border" />}><AccountPlanPage /></Suspense>,
            },
            {
                path: "/account",
                element: <Suspense fallback={<Spinner animation="border" />}><AccountPage /></Suspense>,
            },
            {
                path: "/manage-integrations",
                element: <Suspense fallback={<Spinner animation="border" />}><IntegrationsPage /></Suspense>,
            },
            {
                path: "/manage-api-keys",
                element: <Suspense fallback={<Spinner animation="border" />}><ApiKeysPage /></Suspense>,
            },
            {
                path: "/templates",
                element: <Suspense fallback={<Spinner animation="border" />}><TemplatesPage /></Suspense>,
            },
            {
                path: "/surveys",
                element:<Suspense fallback={<Spinner animation="border" />}> <Surveys /></Suspense>,
            },
            {
                path: "/templates/Create",
                element: <Suspense fallback={<Spinner animation="border" />}><TemplateCreatePage /></Suspense>,
            },
            {
                path: "/templates/edit/:id",
                element: <Suspense fallback={<Spinner animation="border" />}><TemplateEditPage /></Suspense>,
            },
            {
                path: "/templates/preview/:id",
                element: <Suspense fallback={<Spinner animation="border" />}><TemplatePreviewPage /></Suspense>,
            },
        ],
    },
    {
        path: "/surveys/response",
        element: <Suspense fallback={<Spinner animation="border" />}><SurveyResponsePage /></Suspense>,
    },
    {
        path: "/surveys/shopifyposresponse",
        element: <Suspense fallback={<Spinner animation="border" />}><SurveyShopifyPosResponsePage /></Suspense>,
    },
    {
        path: "/shopify/marketingactivity/surveypreview",
        element: <Suspense fallback={<Spinner animation="border" />}><SurveyShopifyMarketingPreviewPage /></Suspense>,
    },
    {
        path: "*",
        element: <Suspense fallback={<Spinner animation="border" />}><NotFoundPage /></Suspense>,
    },
];

const router = createBrowserRouter(routesConfig);

const App = () => {
    var shopifyEmbedHostParam = (new URLSearchParams(window.location.search).get("host") || shopifyAppBridge.host);
    if(shopifyEmbedHostParam){

        shopifyAppBridge.host = shopifyEmbedHostParam;
        
        var shopifyAppBridgeConfig = {
            apiKey: "462ef20391215413232a36f8b188df88",
            host: shopifyEmbedHostParam || "",
            forceRedirect: true
        };
        return <>
           <Provider config={shopifyAppBridgeConfig}>
               <RouterProvider router={router} />
           </Provider>
       </>;
    }
    else {
        return <><RouterProvider router={router} /></>;
    }
};


export default App;