/* tslint:disable */
/* eslint-disable */
/**
 * LoudHippo API
 * An API for LoudHippo Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@loudhippo.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Integration = {
    Shopify: 'Shopify',
    WooCommerce: 'WooCommerce',
    BigCommerce: 'BigCommerce',
    Wix: 'Wix',
    None: 'None'
} as const;

export type Integration = typeof Integration[keyof typeof Integration];



