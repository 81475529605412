import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import { SurveyApi, AccountApi, SurveyJsonViewModel } from "../apiclient";
import { apiConfig } from "../config";
import { FormCheck, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../contexts/AccountContext";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import { axiosInstance } from "../ShopifyAxiosInterceptor";
import { darkToast } from "../js/darktoast";

const ToggleSurveyBanner = (props : any) => {
  const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    let surveyApi = new SurveyApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
    let accountApi = new AccountApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  const [model, setModel] = useState({} as SurveyJsonViewModel);
  const [isLoading, setLoading] = useState(false as boolean);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();
  const [isSurveyEnabled, setIsSurveyEnabled] = useState(
    authContext.account?.surveyEnabled as boolean
  );
  const toggleSurveyEnabled = (val: any) => {
    console.log("toggleSurveyEnabled", val);
    var toggleState = !isSurveyEnabled;
    setIsSurveyEnabled(toggleState);

    surveyApi
      .surveyToggleStatusByIdIdGet(authContext.account?.publishedSurveyId ?? "")
      .then((response) => {
          if (toggleState) {
              darkToast.success("Survey enabled");
          setShowConfetti(true);
          } else {
              darkToast.success("Survey disabled");
        }
        authContext.refresh(); //refresh context;
        props.onToggled();
      })
      .catch((error: any) => {
        console.log("toggle survey error", error);

        setLoading(false);
      });
  };

  useEffect(() => {
    setIsSurveyEnabled(authContext.account?.surveyEnabled as boolean);
}, [authContext.account?.surveyEnabled]);

  return (
    <React.Fragment>
      <div className="">
        {isSurveyEnabled ? (
          <div className="alert alert-success" role="alert">
            <div className="float-end">
              <FormCheck
                name="surveyEnabled"
                className="form-switch-lg surveyToggleSwtich"
                type="switch"
                checked={isSurveyEnabled}
                onChange={toggleSurveyEnabled}
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="w-8 text-lg">
                <i className="bi bi-check-circle-fill"></i>
              </div>
              <span className="font-bold">
              Your post-purchase survey is enabled on your order confirmation page and collecting responses
              </span>
            </div>
            {showConfetti && (
              <Confetti
                width={width}
                height={height}
                recycle={false}
                numberOfPieces={500}
              />
            )}
          </div>
        ) : (
          <div className="alert alert-warning" role="alert">
            <div className="float-end">
              <a
                className="btn btn-primary btn-sm btn-enable-survey"
                onClick={() => toggleSurveyEnabled(true)}
              >
                Enable survey
              </a>
              {/* <FormCheck
                    name="surveyEnabled"
                    className="form-switch-lg"
                    type="switch"
                    checked={isSurveyEnabled}
                    onChange={toggleSurveyEnabled}
                  /> */}

              {/* <label className="form-check-label ms-2" htmlFor="surveyEnabled">
                    Enable
                  </label> */}
            </div>
            <div className="d-flex align-items-center">
              <span className="h4 text-warning font-bold">
                Your post-purchase survey is disabled
              </span>
            </div>
            <div className="">
              <p className="text-sm">
                Enable to add to your order status page and start collecting
                post-purchase responses.
              </p>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ToggleSurveyBanner;
