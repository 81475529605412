import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AccountContext";
import { CrispChatContext } from "./CrispChat";
import SwitchAccountModal from "../pages/account/SwitchAccountModal";
import { shopifyAppBridge } from "../config";


const TopNavBar = (props : any) => {
  const authContext = useContext(AuthContext);
  const crispChatContext = useContext(CrispChatContext);
  const [isSwitchAccountModalShown, setIsSwitchAccountModalShown] =
    useState(false);
  const numProfiles = authContext.account?.associatedAccountIds?.length ?? 1;
  const shopifyEmbedHostParam = (new URLSearchParams(window.location.search).get("host") || shopifyAppBridge.host);
  
  const [hideLogoutFromMenu, setHideLogoutFromMenu] = useState(false as boolean);

  useEffect(() => {
    if(shopifyEmbedHostParam || inIframe()){
      setHideLogoutFromMenu(true);
    }

  }, []);

  function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

  // console.log('top nav host param', shopifyEmbedHostParam)
  //let navigate = useNavigate();
  const logout = (event: any) => {
    (window as any).Intercom("shutdown");
    window.location.href = "/Account/Logout";
  };



  function openHelpChat() {
    console.log("loadHelpChat");
    crispChatContext.openChat();
  }

  const renderEnabledBadge = (isEnabled: boolean) => {
    return (
      <>
        {isEnabled ? (
          <span className="badge bg-opacity-30 bg-success text-success rounded-pill d-inline-flex align-items-center ms-auto">
            enabled
          </span>
        ) : (
          <span className="badge bg-opacity-30 bg-danger text-danger rounded-pill d-inline-flex align-items-center ms-auto">
            disabled
          </span>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-0 py-3">
        <div className="container-fluid">
          {/* <!-- Toggler --> */}
          <button
            className="navbar-toggler ms-n2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExampleXxl"
            aria-controls="navbarsExampleXxl"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <!-- Brand --> */}
          {props.showMenu ? (
          <Link to={`/dashboard`}
          className="navbar-brand d-flex align-items-center gap-2 py-lg-2 px-lg-6 me-0"
        >
          <div className="w-md-auto  text-dark">
            <img src="/img/logos/loudhippo.svg" alt="..." />
          </div>
          <span className="text-white h3 font-bold ls-tight">LoudHippo</span>
        </Link>
          ) : (
            <a
            className="navbar-brand d-flex align-items-center gap-2 py-lg-2 px-lg-6 me-0"
          >
            <div className="w-md-auto  text-dark">
              <img src="/img/logos/loudhippo.svg" alt="..." />
            </div>
            <span className="text-white h3 font-bold ls-tight">LoudHippo</span>
          </a>
          )}

          {/* <!-- Avatar --> */}
          <div className="order-lg-last ms-lg-4 position-relative">
            <a
              className="d-flex align-items-center dropdown"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="d-none d-sm-block ms-3">
                <span className="h6 text-white">
                  {authContext.account?.companyName}
                </span>
                <p className="text-nowrap text-xs text-muted">
                  Plan: {authContext.account?.billingPlan?.toString()}
                </p>
              </div>
              <div className="d-none d-md-block ms-md-5">
                <i className="bi bi-chevron-down text-muted text-xs"></i>
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              {/* <a href="#!" className="dropdown-item">
                    <i className="ni ni-settings-gear-65"></i>
                    <span>Settings</span>
                  </a> */}
                  {props.showMenu &&
               <li>
                   <Link className="dropdown-item" to={`/account`}>
                  <i className="bi bi-person-square"></i> Account settings
                </Link>
                  </li>}

                  {!hideLogoutFromMenu &&
                  <>
                    {numProfiles > 1 &&
                    <li>
                      <a className="dropdown-item" onClick={()=>setIsSwitchAccountModalShown(true)}>
                        <i className="bi bi-arrow-left-right"></i> Switch account
                      </a>
                    </li>
                    }
                    <li>
                      <a className="dropdown-item" onClick={logout}>
                        <i className="bi bi-box-arrow-left"></i> Logout
                      </a>
                    </li>
                  </>
                  }

                  
            </ul>
          </div>
          <div className="collapse navbar-collapse" id="navbarsExampleXxl">
            
            {/* <!-- Nav items --> */}
            {props.showMenu &&
            <div className="navbar-nav mb-4 mb-lg-0 order-lg-1">
               <Link className="nav-item nav-link" to={`/`}>
                <i className="bi bi-house-door"></i> Home
              </Link>
              <Link className="nav-item nav-link" to={`/Surveys/Edit`}>
                <i className="bi bi-bi bi-card-checklist"></i> {"Survey "}
                {renderEnabledBadge(
                  authContext.account?.surveyEnabled ?? false
                )}
              </Link>
              <Link className="nav-item nav-link" to={`/surveys/analytics`}>
                <i className="bi bi-bar-chart"></i> Analytics
              </Link>
              <Link className="nav-item nav-link" to={`/templates`}>
                <i className="bi bi-collection"></i> Question Library
              </Link>
              <Link className="nav-item nav-link" to={`/manage-integrations`}>
                <i className="bi bi-plug"></i> Integrations
              </Link>
              <Link className="nav-item nav-link" to={`/account`}>
                <i className="bi bi-person-square"></i> Account
              </Link>
            </div>
            }
          </div>
        </div>
      </nav>
      {authContext.account &&
      <SwitchAccountModal show={isSwitchAccountModalShown} onHide={()=>setIsSwitchAccountModalShown(false)}  />
      }
    </React.Fragment>
  );
};

export default TopNavBar;
