import React, { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "../contexts/AccountContext";
import { IntercomProvider, useIntercom } from "react-use-intercom";

export interface IntercomChatProps {
  openChat: () => void;
  pushMessageText: (textMessage: string) => void;
}
const initialState = {
  openChat: () => {},
  pushMessageText: (textMessage: string) => {
    console.log("pushMessageText", textMessage);
  },
};

const IntercomChatContext = createContext(initialState);

const IntercomChat = () => {
  const {
    boot,
    shutdown,
    hardShutdown,
    update,
    hide,
    show,
    showMessages,
    showNewMessages,
    getVisitorId,
    startTour,
    trackEvent,
    showArticle,
  } = useIntercom();

  const authContext = useContext(AuthContext);
  useEffect(() => {
    var email = authContext.account?.emailAddress;
    var name = authContext.account?.name;
    var accountId = authContext.account?.accountId;
    
    boot({
      name: name,
      email: email,
      userHash: authContext.account?.intercomUserHash,
      phone: authContext.account?.phone || '',
      userId: authContext.account?.intercomUserId,
      customAttributes: {
        is_owner: authContext.account?.isOwner,
        last_request_at: parseInt((new Date().getTime() / 1000).toString()),
        user_role: authContext.account?.userRole
      },
      company: {
        companyId: accountId || '',
        name: authContext.account?.companyName,
        monthlySpend: authContext.account?.billingSubscriptionPrice,
        plan: authContext.account?.billingPlan,
        website: authContext.account?.integrationDomain,
        industry: authContext.account?.businessCategory,
        customAttributes: {
          business_category: authContext.account?.businessCategory,
          business_type: authContext.account?.businessType,
          platform: authContext.account?.integration,
          account_status: authContext.account?.accountStatus,
          survey_enabled: authContext.account?.surveyEnabled,
          monthly_orders: authContext.account?.monthlyOrderCount,
          notification_best_practice_enabled:
            authContext.account?.notificationBestPracticeEnabled,
          notification_marketing_enabled:
            authContext.account?.notificationMarketingEnabled,
          notification_daily_digest_enabled:
            authContext.account?.notificationDailyDigestEnabled,
          notification_weekly_digest_enabled:
            authContext.account?.notificationWeeklyDigestEnabled,
          integration_plan_name: authContext.account?.integraitonPlanName,
        },
      },
    });
  });

  return <React.Fragment></React.Fragment>;
};

export default IntercomChat;
export { IntercomChatContext };
